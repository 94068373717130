@media (min-width: 900px) {
  html {
    overflow: hidden;
  }
}

@media (min-width: 0px) and (max-width: 900px) {
  html {
    overflow-x: hidden;
  }
}

body {
  margin: 0;
  font-family: 'Rubik-var', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.css-1mhvq4p-MuiTypography-root,
.css-rnivn1-MuiTypography-root,
.css-t70oit-MuiTypography-root,
.css-auk1dh-MuiTypography-root,
.css-1ab3jf4-MuiTypography-root,
.css-auk1dh-MuiTypography-root,
.css-1bym5iu-MuiTypography-root {
  font-size: 1rem !important;
}
